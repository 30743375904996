"use strict";

$(document).ready(function () {
  /* Components */
  $(function () {
    $('.js-loading').each(function () {
      var loading = $(this);

      function toggle(isShow) {
        if (isShow) {
          loading.addClass('loading_visible');
        } else {
          loading.removeClass('loading_visible');
        }
      }

      window.loading = toggle;
    });
    new Swiper('.js-swiper-gallery', {
      slidesPerView: 'auto',
      centeredSlides: true,
      spaceBetween: 20,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        dynamicBullets: true,
        clickable: true
      }
    });
    new Swiper('.js-swiper-reviews', {
      slidesPerView: 1,
      centeredSlides: true,
      spaceBetween: 30,
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      breakpoints: {
        576: {
          slidesPerView: 1
        },
        768: {
          slidesPerView: 2
        },
        992: {
          slidesPerView: 3
        }
      }
    });
    new Swiper('.js-swiper-events', {
      slidesPerView: 1,
      centeredSlides: true,
      loop: true,
      autoplay: {
        delay: 3000
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    });
    new Swiper('.js-swiper-team', {
      slidesPerView: 4,
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
          centeredSlides: true
        },
        576: {
          slidesPerView: 2
        },
        768: {
          slidesPerView: 3
        },
        992: {
          slidesPerView: 4
        }
      }
    });
    $('.js-form').each(function () {
      var form = $(this);
      var buttonRequired = form.find('.js-btn-required');
      buttonRequired.each(function () {
        var clickedButton = $(this);
        clickedButton.on('click', function () {
          var hasError = false;
          var closestForm = $(this).closest(form); // Validation on empty fields

          closestForm.find('.js-required').each(function () {
            if ($(this).val().length === 0) {
              $(this).addClass('input-invalid');
              hasError = true;
            }
          }); // If everything is fine

          if (!hasError) {
            var data = form.serialize();
            var url = form.attr('action');
            var method = form.attr('method');
            sendData(data, url, method);
          }
        });
      });
    }); // Remove incorrect class when user is typing

    $('.js-required').on('input keydown propertychange', function () {
      if ($(this).val().length > 0 && $(this).val() != $(this).attr('placeholder')) {
        $(this).removeClass('input-invalid');
      }
    }); // Add the content after clicking on the button

    $('body').on('click', '.js-form-data-btn', function () {
      var btn = $(this);
      var data = {};
      var url = btn.attr('data-form-data-url');
      var method = btn.attr('data-form-data-method');
      var pageNumber = btn.attr('data-page-number');
      data.page = pageNumber;
      sendData(data, url, method, $(this));
    }); //Send data

    function sendData(data, url, method, button) {
      loading(true);
      $.ajax({
        type: method,
        url: url,
        data: data,
        dataType: 'json',
        processData: true,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        success: function success(data) {
          if (data.refresh) {
            window.location.reload();
          }

          if (data.appendContents && data.appendContents.length > 0) {
            data.appendContents.map(function (appendContent) {
              var name = appendContent.name;
              var content = appendContent.content;
              var block = $('.js-form-data-content[data-form-data-name="' + name + '"]');
              block.append(content);
            });

            if (data.nextPageNumber) {
              var nextPageNumber = data.nextPageNumber;
              button.attr('data-page-number', nextPageNumber);
            } else {
              button.css('display', 'none');
            }

            if (data.reinitiate) {
              reloadLightGallery();
            }
          }

          if (!data.refresh) {
            loading(false);
          }
        },
        error: function error() {
          loading(false);
          alert("Error!");
        }
      });
    }

    var gallery = $('.js-lightgallery');
    var galleryOptions = {
      thumbnail: false,
      width: '1140px',
      height: '600px',
      mode: 'lg-fade',
      addClass: 'fixed-size',
      counter: false,
      download: false,
      hideBarsDelay: 999999
    };
    gallery.lightGallery(galleryOptions);

    function reloadLightGallery() {
      gallery.data('lightGallery').destroy(true);
      gallery.lightGallery(galleryOptions);
    }
  });
});